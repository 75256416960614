.customer-details-item {
    margin-top: 10px;
}
.customer-details-title {
    margin-top: 20px;
    margin-bottom: 10px;
    text-decoration: underline;
    font-weight: 600;
}
.customer-details-label {
    font-weight: 500;
}

.customer-header {
    font-size: 1.4em;
}

.customer-postage-input-area {
    max-width: 400px;
    width: 100%;
    margin-bottom: 40px;
}

.form-field.customer-input-form-field label {
    font-size: 13px;
}

.form-field.customer-input-form-field input {
    display: block;
    margin-top: 5px;
}

.gap-1rem {
    margin: 0 2%;
}

.drop-down-tc {
    padding: 0.88% 2%;
    width: 100%;
}

.wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    column-gap: 2%;
}

.assign-wrapper {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 2%;
}

.activity-wrapper {
    overflow: auto;
    max-height: 100vh;
}

.disabled {
    cursor: not-allowed !important;
}

.ticket-wrapper {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem; */
    margin-bottom: 1rem;
}

.capitalize {
    text-transform: capitalize;
}
.card-ticket-title {
    /* padding: 10px; */
    background-color: #FFFF00;
    font-weight: 600;
    /* border: 1px solid black */
}

.card-note-title {
    /* padding: 10px; */
    background-color: #A4CAFE;
    font-weight: 600;
    /* border: 1px solid black */
}

