.page {
  max-width: 1440px;
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding-top: 20px;
}
.fullpage-modal {
  max-width: 1440px;
  width: 98%;
  height: 50%;
  margin: 0 auto;
  margin-top: 40px;
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}
.pageContent {
  margin-top: 5px;
  padding-bottom: 20px;
}
.pageHeader {
  margin: 0;
}

.form-field {
  margin-bottom: 20px;
}
.form-field label {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
}
.form-field input[type="text"] {
  display: block;
  width: 100%;
  height: 50px;
  margin-top: 5px;
  background: none;
  border: 1px solid #ddd;
  font-family: Roboto, sans-serif;
  padding: 0 12px;
  border-radius: 3px;
  font-size: 14px;
  font-style: normal;
}
.form-field textarea {
  display: block;
  width: 100%;
  height: 120px;
  background: none;
  border: 1px solid #ddd;
  padding: 12px;
  font-size: 14px;
  font-style: normal;
}
.form-field select {
  display: block;
  width: 100%;
  height: 50px;
  margin-top: 5px;
  background: none;
  border: 1px solid #ddd;
  font-family: Roboto, sans-serif;
  padding: 0 12px;
  border-radius: 3px;
  font-size: 14px;
  font-style: normal;
}
.form-submit {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;

  cursor: pointer;
  line-height: 1.5;
  border: none;
  padding: 9px 28px;
  border-radius: 40px;
  font-size: 14px;
  letter-spacing: 0;
  outline: none;
  text-decoration: none;
  background: #ef3354;
  color: #fff;
  width: 100%;
}

.form-submit:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.auth-form-submit:hover {
  opacity: 0.8;
}
/* .table,
.table th,
.table td {
  border: 1px solid #333;
  border-collapse: collapse;
}
.table {
  width: 100%;
} */
.none-screen {
  text-align: center;
}
.info-modal,
.confirm-modal,
.delete-modal {
  position: absolute;
  background: white;
  border: 1px solid #333333;
  outline: none;
}
.info-modal {
  top: calc(50% - 60px);
  left: calc(50% - 200px);
  right: calc(50% - 200px);
  bottom: calc(50% - 60px);
  padding: 20px 30px 20px 50px;
}
.confirm-modal {
  top: calc(50% - 60px);
  left: calc(50% - 190px);
  right: calc(50% - 190px);
  /* bottom: calc(50% - 60px); */
  text-align: center;
  /* padding: 20px 0; */
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}
.confirm-modal-ticket {
  top: calc(50% - 60px);
  left: calc(50% - 190px);
  right: calc(50% - 190px);
  bottom: calc(50% - 60px);
  text-align: center;
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}
.confirm-modal-big {
  top: calc(50% - 115px);
  left: calc(50% - 190px);
  right: calc(50% - 190px);
  bottom: calc(50% - 115px);
  padding: 20px;
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}
.confirm-modal-big-approve {
  top: calc(50% - 85px);
  left: calc(50% - 165px);
  right: calc(50% - 165px);
  bottom: calc(50% - 85px);
  padding: 20px;
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}
.delete-modal {
  top: calc(50% - 80px);
  left: calc(50% - 210px);
  right: calc(50% - 210px);
  bottom: calc(50% - 80px);
  text-align: center;
  padding: 20px 10px;
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}
.info-modal-close,
.confirm-modal-close,
.modal-close {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1rem;
  outline: none;
}
.info-main-text,
.delete-main-text {
  font-weight: bold;
  margin-bottom: 1em;
  font-size: 1em;
}
.info-sub-text {
  font-size: 0.9em;
}
.confirm-main-text {
  margin-bottom: 10px;
}
.delete-sub-text {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.approveDeclineSet button {
  border: none;
  color: white;
  padding: 10px 20px;
  margin-right: 15px;
  cursor: pointer;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.3);
  font-family: inherit;
  border-radius: 10px;
  outline: none;
}
.approveDeclineSet button:hover {
  opacity: 0.7;
}
.approve {
  background: #00b200;
}
.decline {
  background: #f64e4c;
}
button.neutral {
  background: #eeeeee;
  color: black;
}

.button {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;

  cursor: pointer;
  line-height: 1.5;
  border: none;
  padding: 9px 28px;
  border-radius: 10px;
  letter-spacing: 0;
  outline: none;
  text-decoration: none;
  background: #ef3354;
  color: #fff;
}
.button-small {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  border: none;
  padding: 4px 10px;
  border-radius: 10px;
  letter-spacing: 0;
  outline: none;
  text-decoration: none;
  background: #ef3354;
  color: #fff;
}

.deleteButton {
  text-transform: uppercase;
  margin-top: 40px;
  background: #b71c1c;
  border-radius: 10px;
  padding: 9px 15px;
}

/* For mega warranty and user tables */
.admin-table-container,
.admin-filter-area,
.admin-summary-area {
  max-width: 100%;
}
.table.admin-table {
  border: none;
}
.table.admin-table td,
.table.admin-table th {
  border: none;
  border-bottom: 1px solid #333333;
  padding: 8px 5px;
}
.admin-table th {
  text-align: left;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  position: relative;
  padding-right: 5px;
}
.admin-table-sort {
  position: absolute;
  right: 1px;
}

.admin-filter-area {
  font-size: 0.9em;
  text-align: right;
}
.admin-filter-input-container {
  margin-bottom: 10px;
}
.admin-filter-input-label {
  font-size: 0.85em;
}
input.admin-filter-input {
  display: inline-block;
  width: 200px;
  background: none;
  border: 1px solid #ddd;
  font-family: Roboto, sans-serif;
  padding: 6px 8px;
  border-radius: 3px;
  font-style: normal;
}
select.admin-filter-input {
  display: inline-block;
  width: 200px;
  background: none;
  border: 1px solid #ddd;
  font-family: Roboto, sans-serif;
  padding: 6px;
  border-radius: 3px;
  font-style: normal;
}

.admin-summary-area {
  margin-top: 15px;
}
.input-modal {
  position: absolute;
  top: 10%;
  left: calc(50% - 200px);
  width: auto;
  min-width: 30%;

  background-color: white;
  border: 1px solid lightgrey;
  /* padding: 20px; */
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .input-modal {
    width: 100%;
    left: 0px;
    /* min-width: 30%; */
    padding: 2px;
    background-color: white;
    border: 1px solid lightgrey;
    /* padding: 20px; */
    /* border-radius: 5px;/ */
  }
}

.input-modal-small {
  position: absolute;
  top: 20%;
  left: 30%;
  width: auto;

  background-color: white;
  border: 1px solid lightgrey;
  /* padding: 20px; */
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}

.input-modal-date {
  position: absolute;
  width: 40vw;
  top: 10%;
  left: 30%;
  text-align: center;
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}
.input-modal-mid {
  position: absolute;
  top: 10%;
  left: 30%;
  right: calc(50% - 200px);
  width: 100%;
  width: auto;
  height: auto;

  background-color: white;
  box-shadow: 5px 10px 18px #7a7979;
  border-radius: 5px;
}

.input-modal-mobi {
  position: absolute;
  top: 5%;

  border: 1px solid lightgrey;
  background-color: white;
  padding: 10px;
}

.button-cancel {
  display: inline-block;
  vertical-align: middle;

  background: #7a7979;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: 9px 28px;

  color: #fff;
  letter-spacing: 0;
  line-height: 1.5;
  text-align: center;
  font-weight: 400;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
}

.required span:before {
  content: "*";
  color: red;
}

.modal-table {
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 200px);

  width: auto;
  height: auto;

  background-color: white;
  border: 1px solid lightgrey;
  box-shadow: 5px 10px 18px #7a7979;
  padding: 20px;
  border-radius: 5px;
}
.MuiTableCell-root {
  padding-top: 12px !important;
  padding-right: 4px !important;
  padding-bottom: 12px !important;
  padding-left: 10px !important;
}

input[type="file"] {
  display: none;
}

.no-spaces {
  margin: 0px !important;
  padding: 0px !important;
}
.custom-file-upload-outline {
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
  margin: 0px;
  background-color: lightgray;
  color: rgb(0, 0, 0);
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 8px;
}

.custom-file-upload-outline:hover {
  background-color: black;
  color: lightgray;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
  margin: 0px;
  background-color: #4b5563;
  color: white;
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 8px;
}

.half-radius {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.custom-file-upload:hover {
  background-color: white;
  color: #4b5563;
}
.file-upload-container {
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 8px;
  width: 100%;
  margin: 0px !important;
}

.k-btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 44px;
}
@media only screen and (max-width: 600px) {
  .k-btn-responsive {
    margin-top: 10px;
    width: 100%;
  }
}

.k-btn-primary {
  color: #ffffff;
  background-color: #23315f;
  border-color: #23315f;
}
.k-btn-outline-primary {
  /* background-color: #ffffff; */
  opacity: 1;
  background-color: white;
  color: #23315f;
  border-color: #23315f;
}

.k-btn-primary:focus,
.k-btn-primary.focus {
  color: #212020;
  background-color: white;
  border-color: #122b40;
}
.k-btn-outline-primary:focus,
.k-btn-outline-primary.focus {
  color: white;
  background-color: #23315f;
  border-color: #122b40;
}
.k-btn-primary:hover,
.k-btn-primary-full:hover {
  color: black;
  background-color: white;
  border-color: #204d74;
}
.k-btn-outline-primary:hover,
.k-btn-outline-primary-full:hover {
  color: white;
  background-color: #23315f;
  border-color: #122b40;
}

.k-btn-primary-full {
  color: #ffffff;
  background-color: #23315f;
  border-color: #23315f;
  width: 100%;
}
.k-btn-outline-primary-full {
  /* background-color: #ffffff; */
  opacity: 1;
  color: #23315f;
  border-color: #23315f;
  width: 100%;
}
.k-btn-secondary {
  color: #23315f;
  border-color: #23315f;
}
.k-btn-secondary-full {
  color: #23315f;
  border-color: #23315f;
  width: 100%;
}

.k-btn-sm,
.k-btn-group-sm > .btn {
  height: 35px;
  line-height: 1.5;
  /* border-radius: 3px; */
}
.k-btn-xs,
.k-btn-group-xs > .btn {
  height: 28px;
  line-height: 1;
  opacity: 0.8;
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem;
  /* border-radius: 3px; */
}

.k-status {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 8px;
  padding: 5px;
  font-size: 12px;
}

.k-status-ok {
  background-color: #c2efe0;
  color: #31c48d4d;
}
.k-status-pending {
  color: #7939004d;
  background-color: #fbc392;
}

input[type="file"] {
  display: none;
}

.form-input {
  margin-top: 20px;
}

.no-margin {
  margin-top: 0 !important ;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

td,
th {
  padding: 0;
  vertical-align: middle;
}

.edged {
  border-radius: 2px !important;
  /* min-width: 200px; */
  border: 1px solid #767676;
  font-size: 12px !important ;
  background-color: #ffffff;
  border: 1px solid black !important ;
  opacity: 0.8;
}

.bg-solid {
  background-color: white !important;
  opacity: 1 !important;
}
