.single-warranty-details {
    font-size: 0.9em;
}
.single-warranty-details-item {
    margin-top: 10px;
}
.single-warranty-details-label {
    font-weight: 500;
}
.single-warranty-header {
    font-size: 1.4em;
}

.warranty-decline-modal .form-field {
    text-align: center;
}
.warranty-decline-modal .form-field textarea {
    height: 60px;
    margin-top: 10px;
}

.single-warranty-postage-input-area {
    max-width: 400px;
    width: 100%;
    margin-bottom: 40px;
}

.warranty-no-profile-container {
    margin-bottom: 1.2em;
    font-size: 0.8em;
    background-color: #f2f2f2;
    color: #a94442;
    padding: 10px;
    display: inline-block;
}

.warranty-out-of-warranty-flag {
    margin-top: 5px;
    padding: 5px 8px;
    background: #cc0022;
    color: white;
    display: inline-block;
    font-size: 0.7em;
    border-radius: 5px;
}

.form-field.single-warranty-input-form-field label {
    font-size: 13px;
}
.form-field.single-warranty-input-form-field input {
    display: block;
    margin-top: 5px;
}
.gap-1rem{
    margin: 0 2%;
}
.drop-down-tc{
    padding: 0.88% 2%;
    width: 100%;
}

.wrapper{
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    column-gap: 2%;
}
.assign-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2%;
}
/* .activity-wrapper{
    overflow: auto;
    max-height: 100vh;
} */
.custom-select::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
  }

  .error-text{
    color:red;
    font-size: 12px;
  }
  .password-show{
    cursor: pointer;
  }
