.my-devices-active-container {
    width: 100%;
    margin-bottom: 40px;
    border-radius: 10px;
}
.my-devices-add-new {
    color: white;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    background-color: #a94442;
    padding: 5px 10px;
    border: none;
    outline: none;
}
.my-devices-add-new-button-top {
    float: right;
}
.my-devices-add-new-main {
    margin-top: 10px;
}
.my-devices-add-new:hover {
    color: #aaaaaa;
}
.my-devices-add-new-icon {
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    padding-top: 4px;
}
.my-devices-add-new-text {
    font-size: 13px;
    display: inline-block;
}
.my-devices-active-header {
    width: 100%;
    margin: 0;
    background-color: #4B5563;
    color: white;
    font-size: 1.2em;
    font-weight: normal;
    padding: 10px 20px;
    position: relative;
}
.my-devices-active-details-container {
    display: inline-block;
    border: 1px solid #eeeeee;
    padding: 15px 20px;
}
.my-devices-active-details-full-container {
    border: 1px solid #eeeeee;
    padding: 15px 20px;
    width: 100%;
}
.my-devices-active-details-header {
    font-size: 0.8em;
    margin-bottom: 0.4em;
}
.my-devices-active-details-info {
    font-size: 1em;
}

.my-devices-active-header-icon-container {
    position: absolute;
    right: 10px;
    top: 11px;
}
.my-devices-active-header-icon {
    color: white;
    border: 0;
    outline: 0;
    padding: 0;
    background: none;
    font-size: inherit;
    margin: 0 0 0 10px;
    cursor: pointer;
}

.my-devices-warranty {
    margin-bottom: 10px;
}
.my-devices-warranty:last-of-type {
    margin-bottom: 25px;
}

.my-devices-warranty-icon {
    vertical-align: top;
    display: inline-block;
    margin-right: 5px;
}
.my-devices-warranty-date {
    font-size: 0.9em;
    color: #666666;
}
.info-device{
    display: grid;
    grid-template-columns: 1fr 1fr;
}


@media only screen and (max-width: 700px) {

}

.device-card:hover{
    border-radius: 20px;
    /* border: 1px solid #0c4aa6; */
    box-shadow: 0px 5px 10px 0px darkblue
}
.device-single-card:hover{
    border-radius: 20px;
    /* border: 1px solid #0c4aa6; */
    box-shadow: 0px 5px 10px 0px #00000014
}
.device-card {
    width: 100%;
    min-height:168px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #D2D6DC;
    margin-bottom: 20px;
    box-shadow: 0px 5px 10px 0px #00000014
    

}

.device-card-header {
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 500px) {
    .device-card-header {
        font-size: 20px;
        font-weight: 700;
    }
  }

.device-card-body {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #374151;
    

}
@media screen and (max-width: 500px) {
    .device-card-body {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #374151;
        
    }
  }

.device-card-icon{
    width: 24px;
    height: 24px;
    vertical-align: text-bottom;
}
.button-icon{
    width: 10px;
    height: 10px;
    margin-right: 5px;
    vertical-align: middle;
}
.device-card-line{
    margin-top: 5px !important;
    font-size: 14px;
 
}

.device-add-report {
    display: block;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 100%;
    width: 100%;
    margin-top: 60px;
 
}
.device-add-report-img {
    margin: 50px
}

.device-add-report-btn {
    margin: 20px
}

.tbl-row {
    height: 60px;
}

@media only screen and (max-width: 700px) {

}
