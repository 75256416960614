.single-user-details {
    font-size: 0.9em;
}
.single-user-details-item {
    margin-top: 10px;
}
.single-user-details-label {
    font-weight: 500;
}
.single-user-header {
    font-size: 1.4em;
}